import React from 'react'

type MailToProps = {
  email: string
  headers?: {}
  obfuscate?: boolean
}

function toSearchString(params: { [key: string]: string } = {}) {
  return Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
}

function generateMailtoLink(
  email: MailToProps['email'],
  headers: MailToProps['headers']
) {
  let link = `mailto:${email}`
  if (headers) link += `?${toSearchString(headers)}`
  return link
}

const MailTo: React.FCX<MailToProps> = ({
  email,
  headers,
  obfuscate = true,
  ...rest
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (typeof window !== `undefined`) {
      window.location.href = generateMailtoLink(email, headers)
    }
  }
  return obfuscate ? (
    <a onClick={handleClick} href="mailto:obfuscated" {...rest} />
  ) : (
    <a href={generateMailtoLink(email, headers)} {...rest} />
  )
}

export default MailTo
